import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import firestoreService from '../services/api/firestoreService';
import Loading from '../components/loading';

interface ThemeContextType {
  themeMode: 'light' | 'dark';
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, initializing: authInitializing } = useAuth();
  const [themeMode, setThemeMode] = useState<'light' | 'dark' | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authInitializing) return;
    if (user) {
      // fetch theme from firestore
      firestoreService
        .getUserThemeMode(user.uid)
        .then((mode) => {
          setThemeMode(mode);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching theme mode:', error);
          setThemeMode('light');
          setLoading(false);
        });
    } else {
      setThemeMode('light');
      setLoading(false);
    }
  }, [user, authInitializing]);

  const toggleTheme = async () => {
    if (!themeMode || !user) return;
    const newTheme = themeMode === 'light' ? 'dark' : 'light';
    setThemeMode(newTheme);
    if (user) {
      try {
        await firestoreService.setUserThemeMode(user.uid, newTheme);
      } catch (error) {
        console.error('Error updating theme mode:', error);
        setThemeMode(themeMode);
      }
    }
  };

  if (loading || themeMode === null) {
    return <Loading />;
  }

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeMode = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeMode must be used within a ThemeProvider');
  }
  return context;
};

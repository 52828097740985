import React from 'react';
import { useTranslation } from 'react-i18next';
import { useThemeMode } from '../../../contexts/ThemeContext';

const Base64: React.FC = () => {
  const { t } = useTranslation();
  const { themeMode } = useThemeMode();

  return <></>;
};

export default Base64;

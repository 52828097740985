// src/services/api/authService.ts
import { auth } from './firebaseService';
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  User,
} from 'firebase/auth';

interface AuthService {
  watchAuthState(callback: (user: User | null) => void): () => void;
  signInWithGoogle(): Promise<void>;
  signOutUser(): Promise<void>;
}

const watchAuthState = (callback: (user: User | null) => void) => {
  return onAuthStateChanged(auth, callback);
};

const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider);
};

const signOutUser = async () => {
  await signOut(auth);
};

const authService: AuthService = {
  watchAuthState,
  signInWithGoogle,
  signOutUser,
};

export default authService;

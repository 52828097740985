// src/modules/login/index.tsx
import React, { useEffect, useState } from 'react';
import styles from './Login.module.scss';
import logo from '../../assets/logo-white.png';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import Loading from '../../components/loading';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, signIn } = useAuth();

  useEffect(() => {
    // If user is already logged in, navigate to home
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      await signIn();
      toast.success('Signed in successfully');
      navigate('/');
    } catch (error) {
      toast.error('Failed to sign in. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={styles.loginContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <div className={styles.formContainer}>
            <Typography variant="h6">Login</Typography>
            <Divider className={styles.divider} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleGoogleSignIn}
            >
              Sign in with Google
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;

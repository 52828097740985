// src/components/not-found/index.tsx
import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useThemeMode } from '../../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface NotFoundProps {
  message?: string; // message can be optional because we use i18n
}

const NotFound: React.FC<NotFoundProps> = ({ message }) => {
  const { t } = useTranslation();
  const { themeMode } = useThemeMode();
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor={themeMode === 'dark' ? '#121212' : '#f5f5f5'}
      p={2}
    >
      <ErrorOutlineIcon
        style={{
          fontSize: 80,
          marginBottom: 16,
          color: themeMode === 'dark' ? '#fff' : '#000',
        }}
      />
      <Typography
        variant="h4"
        gutterBottom
        style={{ color: themeMode === 'dark' ? '#fff' : '#000' }}
      >
        {message || t('notFound.message')}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoHome}
        sx={{ mt: 2 }}
      >
        {t('notFound.goHome')}
      </Button>
    </Box>
  );
};

export default NotFound;

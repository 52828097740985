// src/components/sidebar/index.tsx
import React, { useState, MouseEvent } from 'react';
import {
  Drawer,
  List,
  ListItemButton,
  IconButton,
  Divider,
  Avatar,
  FormControl,
  Select,
  Switch,
  Typography,
  Box,
  SelectChangeEvent,
  PopoverProps,
  Collapse,
  ListItemIcon,
  ListItemText,
  MenuItem as MuiMenuItem,
} from '@mui/material';
import {
  Settings,
  ChevronLeft,
  ChevronRight,
  AccountCircle,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useThemeMode } from '../../contexts/ThemeContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './Sidebar.module.scss';
import { Tooltip } from '../common/tooltip';
import { ArrowPaper } from '../common/arrow-paper';
import Button from '../common/button';
import menuItemsData from '../../data/menuItems';
import Submenu from '../common/submenu';
import firestoreService from '../../services/api/firestoreService';

interface SidebarProps {
  collapsed: boolean;
  onCollapseToggle: () => void;
}

const menuPopoverOrigin: PopoverProps['anchorOrigin'] = {
  vertical: 'center',
  horizontal: 'right',
};

const menuTransformOrigin: PopoverProps['transformOrigin'] = {
  vertical: 'center',
  horizontal: 'left',
};

const Sidebar: React.FC<SidebarProps> = ({ collapsed, onCollapseToggle }) => {
  const { user, signOut } = useAuthContext();
  const { themeMode, toggleTheme } = useThemeMode();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [userAnchorEl, setUserAnchorEl] = useState<HTMLElement | null>(null);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<HTMLElement | null>(
    null
  );

  const defaultLanguage = i18n.language || 'en';
  const [selectedLanguage, setSelectedLanguage] =
    useState<string>(defaultLanguage);

  const [expandedMenus, setExpandedMenus] = useState<{
    [key: string]: boolean;
  }>({});
  const [popupAnchors, setPopupAnchors] = useState<{
    [key: string]: HTMLElement | null;
  }>({});

  const closeSubMenus = () => {
    Object.keys(popupAnchors).forEach((key) => {
      setPopupAnchors((prev) => ({ ...prev, [key]: null }));
    });
    setSettingsAnchorEl(null);
    setUserAnchorEl(null);
  };

  const handleUserIconClick = (event: MouseEvent<HTMLElement>) => {
    if (userAnchorEl) {
      setUserAnchorEl(null);
    } else {
      closeSubMenus();
      if (window.innerWidth < 768 && !collapsed) {
        onCollapseToggle();
      }
      setUserAnchorEl(event.currentTarget);
    }
  };

  const handleSettingsIconClick = (event: MouseEvent<HTMLElement>) => {
    if (settingsAnchorEl) {
      setSettingsAnchorEl(null);
    } else {
      closeSubMenus();
      if (window.innerWidth < 768 && !collapsed) {
        onCollapseToggle();
      }
      setSettingsAnchorEl(event.currentTarget);
    }
  };

  const handleCollapseIconClick = (event: MouseEvent<HTMLElement>) => {
    if (collapsed) {
      closeSubMenus();
    }
    onCollapseToggle();
  };

  const handleUserMenuClose = () => setUserAnchorEl(null);
  const handleSettingsMenuClose = () => setSettingsAnchorEl(null);

  const handleLanguageChange = async (event: SelectChangeEvent) => {
    const newLang = event.target.value as string;
    setSelectedLanguage(newLang);
    await i18n.changeLanguage(newLang);
    if (user) {
      await firestoreService.setUserLanguage(user.uid, newLang);
    }
  };

  const handleThemeToggle = async () => {
    toggleTheme();
    const newTheme = themeMode === 'light' ? 'dark' : 'light';
    if (user) {
      await firestoreService.setUserThemeMode(user.uid, newTheme);
    }
  };

  const handleSignOut = async () => {
    await signOut();
    handleUserMenuClose();
  };

  const isMenuActive = (route: string) => location.pathname.startsWith(route);
  const isSubMenuActive = (route: string) => location.pathname === route;

  const handleMenuClick = (
    menuName: string,
    event?: MouseEvent<HTMLElement>
  ) => {
    if (collapsed) {
      if (menuName === undefined) return;
      closeSubMenus();
      const currentAnchor = popupAnchors[menuName] || null;
      const newAnchor = currentAnchor ? null : event?.currentTarget || null;
      setPopupAnchors((prev) => ({ ...prev, [menuName]: newAnchor }));
    } else {
      setExpandedMenus((prev) => ({ ...prev, [menuName]: !prev[menuName] }));
    }
  };

  const handleMenuPopupClose = (menuName: string) => {
    setPopupAnchors((prev) => ({ ...prev, [menuName]: null }));
  };

  const renderSubMenu = (items: any[], isPopup: boolean) => (
    <Box sx={{ minWidth: 220 }}>
      <List
        component="div"
        disablePadding
        sx={{ pl: isPopup ? 0 : 4 }}
        className={styles.submenuPopup}
      >
        {items.map((item: any, idx: number) => {
          const active = isSubMenuActive(item.route);
          const subLabel = t(`sidebar.${item.nameKey}`);
          return (
            <React.Fragment key={item.nameKey}>
              <Tooltip title={subLabel} arrow>
                <Box sx={{ pointerEvents: 'auto' }}>
                  <ListItemButton
                    className={`${active ? styles.highlight : ''} ${!collapsed ? styles.submenuItemExpanded : ''}`}
                    onClick={() => {
                      navigate(item.route);
                    }}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={subLabel} />
                  </ListItemButton>
                </Box>
              </Tooltip>
              {idx < items.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );

  const renderMenu = (menu: any) => {
    const menuActive = isMenuActive(menu.route);
    const menuName = menu.nameKey;
    const open = expandedMenus[menuName] || false;
    const anchorEl = popupAnchors[menuName] || null;
    const menuLabel = t(`sidebar.${menu.nameKey}`);

    return (
      <React.Fragment key={menu.nameKey}>
        <Tooltip title={menuLabel} arrow>
          <ListItemButton
            onClick={(e) => handleMenuClick(menuName, e)}
            className={`${styles.menuItem} ${menuActive ? styles.highlight : ''}`}
          >
            <IconButton>{menu.icon}</IconButton>
            {!collapsed && <span className={styles.menuText}>{menuLabel}</span>}
            {!collapsed &&
              menu.subMenu &&
              (open ? <ExpandLess /> : <ExpandMore />)}
          </ListItemButton>
        </Tooltip>

        {!collapsed && menu.subMenu && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            {renderSubMenu(menu.subMenu, false)}
          </Collapse>
        )}

        {menu.subMenu && (
          <Submenu
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => handleMenuPopupClose(menuName)}
            anchorOrigin={menuPopoverOrigin}
            transformOrigin={menuTransformOrigin}
            slotProps={{ paper: { component: ArrowPaper } }}
            heading={menuLabel}
            footer={undefined}
          >
            {renderSubMenu(menu.subMenu, true)}
          </Submenu>
        )}
      </React.Fragment>
    );
  };

  const UserProfileContent = (
    <Box display="flex" flexDirection="column" width={220}>
      <Box display="flex" alignItems="center" mb={2}>
        {user?.photoURL ? (
          <Avatar src={user.photoURL} alt={user.displayName || 'User'} />
        ) : (
          <Avatar>
            <AccountCircle />
          </Avatar>
        )}
        <Box ml={2}>
          <Typography variant="subtitle1" fontWeight="bold">
            {user?.displayName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {user?.email}
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const UserProfileFooter = (
    <Box display="flex" flexDirection="column" width={220}>
      <Box>
        <Button
          label={t('sidebar.signOut')}
          variant="contained"
          color="primary"
          onClick={handleSignOut}
          sx={{ width: '100%' }}
        />
      </Box>
    </Box>
  );

  const SettingsContent = (
    <Box display="flex" flexDirection="column" width={220}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="body1">{t('sidebar.language')}</Typography>
        <FormControl size="small" sx={{ minWidth: 100 }}>
          <Select value={selectedLanguage} onChange={handleLanguageChange}>
            <MuiMenuItem value="en">English</MuiMenuItem>
            <MuiMenuItem value="fr">French</MuiMenuItem>
          </Select>
        </FormControl>
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body1">{t('sidebar.theme')}</Typography>
        <Box display="flex" alignItems="center">
          <span>☀️</span>
          <Switch
            checked={themeMode === 'dark'}
            onChange={handleThemeToggle}
            color="default"
          />
          <span>🌙</span>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        className: `${styles.drawerPaper} ${collapsed ? styles.collapsed : ''}`,
      }}
      className={styles.drawer}
    >
      <div className={styles.topIcons} style={{ overflowX: 'hidden' }}>
        <List>{menuItemsData.map((menu: any) => renderMenu(menu))}</List>
      </div>
      <div className={styles.bottomIcons}>
        <Divider />
        <List>
          {/* User Profile Icon */}
          <Tooltip
            className={styles.menuItem}
            title={t('sidebar.userProfileHover')}
            arrow
          >
            <ListItemButton
              className={styles.menuItem}
              onClick={handleUserIconClick}
            >
              <IconButton>
                {user?.photoURL ? (
                  <Avatar
                    src={user.photoURL}
                    alt={user.displayName || 'User'}
                    className={styles.avatar}
                  />
                ) : (
                  <AccountCircle />
                )}
              </IconButton>
              {!collapsed && user && (
                <div className={styles.userDetails}>
                  <span>{user.displayName}</span>
                </div>
              )}
            </ListItemButton>
          </Tooltip>

          <Submenu
            open={Boolean(userAnchorEl)}
            anchorEl={userAnchorEl}
            onClose={handleUserMenuClose}
            anchorOrigin={menuPopoverOrigin}
            transformOrigin={menuTransformOrigin}
            slotProps={{ paper: { component: ArrowPaper } }}
            heading={t('sidebar.profile')}
            footer={UserProfileFooter}
          >
            {UserProfileContent}
          </Submenu>
          {/* Settings Icon */}
          <Tooltip title={t('sidebar.settingsHover')} arrow>
            <ListItemButton
              className={styles.menuItem}
              onClick={handleSettingsIconClick}
            >
              <IconButton>
                <Settings />
              </IconButton>
              {!collapsed && (
                <span className={styles.menuText}>{t('sidebar.settings')}</span>
              )}
            </ListItemButton>
          </Tooltip>

          <Submenu
            open={Boolean(settingsAnchorEl)}
            anchorEl={settingsAnchorEl}
            onClose={handleSettingsMenuClose}
            anchorOrigin={menuPopoverOrigin}
            transformOrigin={menuTransformOrigin}
            slotProps={{ paper: { component: ArrowPaper } }}
            heading={t('sidebar.settings')}
            footer={undefined}
          >
            {SettingsContent}
          </Submenu>
          {/* Collapse/Expand Icon */}
          <Tooltip
            title={collapsed ? t('sidebar.expand') : t('sidebar.collapse')}
            arrow
          >
            <ListItemButton
              onClick={handleCollapseIconClick}
              className={styles.menuItem}
            >
              <IconButton>
                {collapsed ? <ChevronRight /> : <ChevronLeft />}
              </IconButton>
              {!collapsed && (
                <span className={styles.menuText}>
                  {collapsed ? t('sidebar.expand') : t('sidebar.collapse')}
                </span>
              )}
            </ListItemButton>
          </Tooltip>
        </List>
        <footer className={styles.footer}>
          {collapsed ? (
            <span>&nbsp;</span>
          ) : (
            <span>
              &copy; {new Date().getFullYear()} {t('sidebar.footerText')}
            </span>
          )}
        </footer>
      </div>
    </Drawer>
  );
};

export default Sidebar;

// src/data/menuItems.tsx
import {
  Note as NoteIcon,
  Language,
  Lock,
  TextFields,
  Code,
  EnhancedEncryption,
  Build,
  VpnKey,
  Security,
  Autorenew,
  FormatAlignLeft,
  CompareArrows,
  Pageview,
  LockOpen,
  Description,
} from '@mui/icons-material';
import { ReactNode } from 'react';

interface SubMenuItem {
  nameKey: string; // i18n key
  icon: ReactNode;
  route: string;
}

interface MenuItem {
  nameKey: string; // i18n key
  icon: ReactNode;
  route: string;
  subMenu?: SubMenuItem[];
}

const menuItems: MenuItem[] = [
  {
    nameKey: 'password',
    icon: <Lock />,
    route: '/password/',
    subMenu: [
      {
        nameKey: 'passwordGenerator',
        icon: <VpnKey />,
        route: '/password/generator',
      },
      {
        nameKey: 'passwordStrengthChecker',
        icon: <Security />,
        route: '/password/strength-checker',
      },
    ],
  },
  {
    nameKey: 'text',
    icon: <TextFields />,
    route: '/text/',
    subMenu: [
      {
        nameKey: 'base64',
        icon: <Autorenew />,
        route: '/text/base64',
      },
    ],
  },
  {
    nameKey: 'json',
    icon: <Code />,
    route: '/json/',
    subMenu: [
      {
        nameKey: 'jsonFormatter',
        icon: <FormatAlignLeft />,
        route: '/json/formatter',
      },
      {
        nameKey: 'jsonComparator',
        icon: <CompareArrows />,
        route: '/json/comparator',
      },
      {
        nameKey: 'jsonViewer',
        icon: <Pageview />,
        route: '/json/viewer',
      },
    ],
  },
  {
    nameKey: 'jwt',
    icon: <EnhancedEncryption />,
    route: '/jwt/',
    subMenu: [
      {
        nameKey: 'jwtDecoder',
        icon: <LockOpen />,
        route: '/jwt/decoder',
      },
    ],
  },
  {
    nameKey: 'utilities',
    icon: <Build />,
    route: '/utilities/',
    subMenu: [
      {
        nameKey: 'notes',
        icon: <Description />,
        route: '/utilities/notes',
      },
    ],
  },
];

export default menuItems;

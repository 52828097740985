// src/services/api/firestoreService.ts
import { db } from './firebaseService';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';

interface FirestoreService {
  getUserThemeMode(uid: string): Promise<'light' | 'dark'>;
  setUserThemeMode(uid: string, mode: 'light' | 'dark'): Promise<void>;
  getUserLanguage(uid: string): Promise<string>;
  setUserLanguage(uid: string, language: string): Promise<void>;
}

const getUserThemeMode = async (uid: string): Promise<'light' | 'dark'> => {
  const userDocRef = doc(db, 'users', uid);
  const userDoc = await getDoc(userDocRef);
  if (userDoc.exists()) {
    const data = userDoc.data();
    return data.themeMode || 'light';
  } else {
    await setDoc(userDocRef, { themeMode: 'light' });
    return 'light';
  }
};

const setUserThemeMode = async (uid: string, mode: 'light' | 'dark') => {
  const userDocRef = doc(db, 'users', uid);
  await updateDoc(userDocRef, { themeMode: mode });
};

const getUserLanguage = async (uid: string): Promise<string> => {
  const userDocRef = doc(db, 'users', uid);
  const userDoc = await getDoc(userDocRef);
  if (userDoc.exists()) {
    const data = userDoc.data();
    return data.language || 'en'; // Default to 'en' if not set
  } else {
    await setDoc(userDocRef, { language: 'en' });
    return 'en';
  }
};

const setUserLanguage = async (uid: string, language: string) => {
  const userDocRef = doc(db, 'users', uid);
  // If user doc doesn't exist, setDoc or updateDoc will fail. Check first:
  const userDoc = await getDoc(userDocRef);
  if (!userDoc.exists()) {
    await setDoc(userDocRef, { language });
  } else {
    await updateDoc(userDocRef, { language });
  }
};

const firestoreService: FirestoreService = {
  getUserThemeMode,
  setUserThemeMode,
  getUserLanguage,
  setUserLanguage,
};

export default firestoreService;

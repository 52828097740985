// src/app/routes/AppRoutes.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../../modules/login';
import Dashboard from '../../modules/dashboard';
import MainLayout from '../layouts/MainLayout';
import LoginLayout from '../layouts/LoginLayout';
import ProtectedRoute from './ProtectedRoute';
import NotFound from '../../components/not-found';

import PasswordGenerator from '../../features/password/pages/PasswordGenerator';
import PasswordStrengthChecker from '../../features/password/pages/PasswordStrengthChecker';
import Base64 from '../../features/text/pages/Base64';
import JSONFormatter from '../../features/json/pages/JSONFormatter';
import JSONComparator from '../../features/json/pages/JSONComparator';
import JSONViewer from '../../features/json/pages/JSONViewer';
import JWTDecoder from '../../features/jwt/pages/JWTDecoder';
import Notes from '../../features/utilities/pages/Notes';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <LoginLayout>
            <Login />
          </LoginLayout>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <MainLayout>
              <Routes>
                <Route path="/" element={<Dashboard />} />

                {/* Password */}
                <Route
                  path="/password/generator"
                  element={<PasswordGenerator />}
                />
                <Route
                  path="/password/strength-checker"
                  element={<PasswordStrengthChecker />}
                />

                {/* Text */}
                <Route path="/text/base64" element={<Base64 />} />

                {/* JSON */}
                <Route path="/json/formatter" element={<JSONFormatter />} />
                <Route path="/json/comparator" element={<JSONComparator />} />
                <Route path="/json/viewer" element={<JSONViewer />} />

                {/* JWT */}
                <Route path="/jwt/decoder" element={<JWTDecoder />} />

                {/* Utilities */}
                <Route path="/utilities/notes" element={<Notes />} />

                {/* If no route matches, show NotFound */}
                <Route
                  path="*"
                  element={
                    <NotFound message="The Page you are looking for is Not Found" />
                  }
                />
              </Routes>
            </MainLayout>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
